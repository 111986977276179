import * as Sentry from '@sentry/vue';
import posthog from 'posthog-js';
import type { User } from '../server/cookie';

export type SentrySetUser = (user: User) => void;
export type SentryCaptureException = (error: Error) => void;

export default defineNuxtPlugin({
  name: 'sentry',
  parallel: true,
  async setup(nuxtApp) {
    const vueApp = nuxtApp.vueApp;
    const router = useRouter();
    const runtimeConfig = useRuntimeConfig();
    const config = runtimeConfig.public;

    Sentry.init({
      app: vueApp,
      dsn: config.sentry_dsn,
      enabled: config.env !== 'development',
      integrations: [
        new Sentry.BrowserTracing({
          routingInstrumentation: Sentry.vueRouterInstrumentation(router),
          tracingOrigins: [config.sentry_tracing_origins, /^\//],
        }),
        new posthog.SentryIntegration(posthog, config.sentry_organization, Number(config.sentry_project_id)),
      ],
      logErrors: config.env !== 'production', // Note that this doesn't seem to work with nuxt 3
      tracesSampleRate: Number(config.sentry_traces_sample_rate), // Sentry recommends adjusting this value in production
      debug: config.env !== 'production',
      environment: config.hub_env,

      // The following enables exeptions to be logged to console despite logErrors being set to false (preventing them from being passed to the default Vue err handler)
      beforeSend(event, hint) {
        // Check if it is an exception, and if so, log it.
        if (event.exception) {
          console.error(`[Exeption handled by Sentry]: (${hint.originalException})`, { event, hint });
        }
        // Continue sending to Sentry
        return event;
      },
    });

    vueApp.mixin(Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    }));

    Sentry.attachErrorHandler(vueApp, {
      logErrors: false,
      attachProps: true,
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    });

    return {
      provide: {
        sentrySetContext: (n: string, context: { [key: string]: string }): void => Sentry.setContext(n, context),
        sentrySetUser: (user: User): void => { Sentry.setUser(user); },
        sentrySetTag: (tagName: string, value: string): void => Sentry.setTag(tagName, value),
        sentryAddBreadcrumb: (breadcrumb: Sentry.Breadcrumb): void => Sentry.addBreadcrumb(breadcrumb),
        sentryCaptureException: (error: Error): string => Sentry.captureException(error),
      },
    };
  },
});
