/**
 * Helpers related to the loaded instance
 */
import { useI18n } from 'vue-i18n';
import type { InstanceName } from './warehouse';
import type { CategorySystem } from '~~/types/categories.types';
import type { NuxtRuntimeConfig } from '@nuxt/types/config/runtime';

export interface InstanceType {
  api: {
    host: string;
    client_id: string;
  };
  favicon: string;
  base_url: string;
  logo: {
    src: string;
  };
}

interface LogoType {
  src: string;
  alt: string;
}

interface FaviconType {
  rel: string;
  href: string;
  type: string;
}

const DEFAULT_INSTANCE_NAME = 'cant';

/**
 * Get Instance name or set a default value
 * @returns {string} instance name
 */
export const getInstanceName = (): InstanceName => instanceName() || DEFAULT_INSTANCE_NAME;

/**
 * Get instance config from nuxt public runtime config
 * @returns {InstanceType} instance config
 */
const instanceConfig = (): InstanceType => (useRuntimeConfig() as NuxtRuntimeConfig).
  public.instances[getInstanceName()];

/**
 * get instance config host
 * @returns {string} instance api host
 */
export function useInstancePath(): string {
  return instanceConfig().api.host;
}

/**
 * Find the instance logo data
 * @returns {LogoType} logo data
 */
export function useLogo(): LogoType {
  const logo = instanceConfig().logo;
  const { t } = useI18n();

  return {
    src: logo.src,
    alt: t(`instance.${getInstanceName()}.title`),
  };
}


/**
 * Find the instance favicon data
 * @returns {FaviconType} favicon data
 */
export function useFavicon(): FaviconType {
  const findIconType = (href: string): string => {
    return (href.split('.').pop() == 'png') ? 'image/png' : 'image/x-icon';
  };

  const findIconHref = (): string => instanceConfig().favicon;
  const href = findIconHref();

  return {
    rel: 'shortcut icon',
    href: href,
    type: findIconType(href),
  };
}

/**
 * An object that maps instance names to an array of category names.
 */
const categoriesByInstances: { [key in InstanceName]: CategorySystem[] } = {
  eden: ['BISAC', 'CLIL', 'THEMA'],
  enqc: ['BISAC', 'THEMA'],
  edgt: ['BISAC', 'THEMA'],
  libr: ['BIC', 'BISAC', 'THEMA'],
  cant: ['BISAC', 'THEMA'],
  csco: ['BISAC', 'THEMA'],
};

/**
 * Returns an array of available categories for the current instance.
 * @returns {category[]} An array of available categories.
 */
export function availableCategories(): CategorySystem[] {
  const instanceName = getInstanceName();
  return categoriesByInstances[instanceName];
}
