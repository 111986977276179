import { default as _401l2uIbdYP19Meta } from "/opt/buildhome/repo/pages/401.vue?macro=true";
import { default as _4047Xi4OILrTdMeta } from "/opt/buildhome/repo/pages/404.vue?macro=true";
import { default as _500ZgVhh9gs5lMeta } from "/opt/buildhome/repo/pages/500.vue?macro=true";
import { default as indexWiy4wlnDxvMeta } from "/opt/buildhome/repo/pages/collections/index.vue?macro=true";
import { default as collections67nvDxiH0zMeta } from "/opt/buildhome/repo/pages/collections.vue?macro=true";
import { default as indexA1sYisaHgRMeta } from "/opt/buildhome/repo/pages/contributors/index.vue?macro=true";
import { default as contributorsYROTh9i6McMeta } from "/opt/buildhome/repo/pages/contributors.vue?macro=true";
import { default as error5bJL3YgCAEMeta } from "/opt/buildhome/repo/pages/error.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as activitiesQRBJmeQrZZMeta } from "/opt/buildhome/repo/pages/organizations/[id]/activities.vue?macro=true";
import { default as index9gaU5USwTVMeta } from "/opt/buildhome/repo/pages/organizations/[id]/index.vue?macro=true";
import { default as _91_91step_93_93M8OaWH38JfMeta } from "/opt/buildhome/repo/pages/publication-drafts/[id]/[[step]].vue?macro=true";
import { default as indexl7xVAEJwVKMeta } from "/opt/buildhome/repo/pages/publication-drafts/index.vue?macro=true";
import { default as newBCpPm9vGqsMeta } from "/opt/buildhome/repo/pages/publication-drafts/new.vue?macro=true";
import { default as categories0fKa5KsSboMeta } from "/opt/buildhome/repo/pages/publications/[id]/categories.vue?macro=true";
import { default as contributorsdcI3sfM7uNMeta } from "/opt/buildhome/repo/pages/publications/[id]/contributors.vue?macro=true";
import { default as formats8n9GQOldXwMeta } from "/opt/buildhome/repo/pages/publications/[id]/formats.vue?macro=true";
import { default as indexN7hqAlxdPaMeta } from "/opt/buildhome/repo/pages/publications/[id]/index.vue?macro=true";
import { default as library_45offersqzGRtfuqkSMeta } from "/opt/buildhome/repo/pages/publications/[id]/library-offers.vue?macro=true";
import { default as mainECCpzpK5WkMeta } from "/opt/buildhome/repo/pages/publications/[id]/main.vue?macro=true";
import { default as pricesDuXLkf6bSDMeta } from "/opt/buildhome/repo/pages/publications/[id]/prices.vue?macro=true";
import { default as related_45contents3O53Eyz648Meta } from "/opt/buildhome/repo/pages/publications/[id]/related-contents.vue?macro=true";
import { default as sales_45rights1EbwwRvFQ0Meta } from "/opt/buildhome/repo/pages/publications/[id]/sales-rights.vue?macro=true";
import { default as indexSUwaqOBqZdMeta } from "/opt/buildhome/repo/pages/publications/index.vue?macro=true";
import { default as publicationsOf6b0iFshDMeta } from "/opt/buildhome/repo/pages/publications.vue?macro=true";
import { default as securityuuQCcpTopPMeta } from "/opt/buildhome/repo/pages/user/security.vue?macro=true";
import { default as settings58UOXn7mWiMeta } from "/opt/buildhome/repo/pages/user/settings.vue?macro=true";
export default [
  {
    name: _401l2uIbdYP19Meta?.name ?? "401",
    path: _401l2uIbdYP19Meta?.path ?? "/401",
    meta: _401l2uIbdYP19Meta || {},
    alias: _401l2uIbdYP19Meta?.alias || [],
    redirect: _401l2uIbdYP19Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/401.vue").then(m => m.default || m)
  },
  {
    name: _4047Xi4OILrTdMeta?.name ?? "404",
    path: _4047Xi4OILrTdMeta?.path ?? "/404",
    meta: _4047Xi4OILrTdMeta || {},
    alias: _4047Xi4OILrTdMeta?.alias || [],
    redirect: _4047Xi4OILrTdMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/404.vue").then(m => m.default || m)
  },
  {
    name: _500ZgVhh9gs5lMeta?.name ?? "500",
    path: _500ZgVhh9gs5lMeta?.path ?? "/500",
    meta: _500ZgVhh9gs5lMeta || {},
    alias: _500ZgVhh9gs5lMeta?.alias || [],
    redirect: _500ZgVhh9gs5lMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/500.vue").then(m => m.default || m)
  },
  {
    name: collections67nvDxiH0zMeta?.name ?? undefined,
    path: collections67nvDxiH0zMeta?.path ?? "/collections",
    meta: collections67nvDxiH0zMeta || {},
    alias: collections67nvDxiH0zMeta?.alias || [],
    redirect: collections67nvDxiH0zMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/collections.vue").then(m => m.default || m),
    children: [
  {
    name: indexWiy4wlnDxvMeta?.name ?? "collections",
    path: indexWiy4wlnDxvMeta?.path ?? "",
    meta: indexWiy4wlnDxvMeta || {},
    alias: indexWiy4wlnDxvMeta?.alias || [],
    redirect: indexWiy4wlnDxvMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/collections/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: contributorsYROTh9i6McMeta?.name ?? undefined,
    path: contributorsYROTh9i6McMeta?.path ?? "/contributors",
    meta: contributorsYROTh9i6McMeta || {},
    alias: contributorsYROTh9i6McMeta?.alias || [],
    redirect: contributorsYROTh9i6McMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/contributors.vue").then(m => m.default || m),
    children: [
  {
    name: indexA1sYisaHgRMeta?.name ?? "contributors",
    path: indexA1sYisaHgRMeta?.path ?? "",
    meta: indexA1sYisaHgRMeta || {},
    alias: indexA1sYisaHgRMeta?.alias || [],
    redirect: indexA1sYisaHgRMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/contributors/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: error5bJL3YgCAEMeta?.name ?? "error",
    path: error5bJL3YgCAEMeta?.path ?? "/error",
    meta: error5bJL3YgCAEMeta || {},
    alias: error5bJL3YgCAEMeta?.alias || [],
    redirect: error5bJL3YgCAEMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/error.vue").then(m => m.default || m)
  },
  {
    name: indexniDiYCWjuTMeta?.name ?? "index",
    path: indexniDiYCWjuTMeta?.path ?? "/",
    meta: indexniDiYCWjuTMeta || {},
    alias: indexniDiYCWjuTMeta?.alias || [],
    redirect: indexniDiYCWjuTMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: activitiesQRBJmeQrZZMeta?.name ?? "organizations-id-activities",
    path: activitiesQRBJmeQrZZMeta?.path ?? "/organizations/:id()/activities",
    meta: activitiesQRBJmeQrZZMeta || {},
    alias: activitiesQRBJmeQrZZMeta?.alias || [],
    redirect: activitiesQRBJmeQrZZMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/organizations/[id]/activities.vue").then(m => m.default || m)
  },
  {
    name: index9gaU5USwTVMeta?.name ?? "organizations-id",
    path: index9gaU5USwTVMeta?.path ?? "/organizations/:id()",
    meta: index9gaU5USwTVMeta || {},
    alias: index9gaU5USwTVMeta?.alias || [],
    redirect: index9gaU5USwTVMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/organizations/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: _91_91step_93_93M8OaWH38JfMeta?.name ?? "publication-drafts-id-step",
    path: _91_91step_93_93M8OaWH38JfMeta?.path ?? "/publication-drafts/:id()/:step?",
    meta: _91_91step_93_93M8OaWH38JfMeta || {},
    alias: _91_91step_93_93M8OaWH38JfMeta?.alias || [],
    redirect: _91_91step_93_93M8OaWH38JfMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/publication-drafts/[id]/[[step]].vue").then(m => m.default || m)
  },
  {
    name: indexl7xVAEJwVKMeta?.name ?? "publication-drafts",
    path: indexl7xVAEJwVKMeta?.path ?? "/publication-drafts",
    meta: indexl7xVAEJwVKMeta || {},
    alias: indexl7xVAEJwVKMeta?.alias || [],
    redirect: indexl7xVAEJwVKMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/publication-drafts/index.vue").then(m => m.default || m)
  },
  {
    name: newBCpPm9vGqsMeta?.name ?? "publication-drafts-new",
    path: newBCpPm9vGqsMeta?.path ?? "/publication-drafts/new",
    meta: newBCpPm9vGqsMeta || {},
    alias: newBCpPm9vGqsMeta?.alias || [],
    redirect: newBCpPm9vGqsMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/publication-drafts/new.vue").then(m => m.default || m)
  },
  {
    name: publicationsOf6b0iFshDMeta?.name ?? undefined,
    path: publicationsOf6b0iFshDMeta?.path ?? "/publications",
    meta: publicationsOf6b0iFshDMeta || {},
    alias: publicationsOf6b0iFshDMeta?.alias || [],
    redirect: publicationsOf6b0iFshDMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/publications.vue").then(m => m.default || m),
    children: [
  {
    name: categories0fKa5KsSboMeta?.name ?? "publications-id-categories",
    path: categories0fKa5KsSboMeta?.path ?? ":id()/categories",
    meta: categories0fKa5KsSboMeta || {},
    alias: categories0fKa5KsSboMeta?.alias || [],
    redirect: categories0fKa5KsSboMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/publications/[id]/categories.vue").then(m => m.default || m)
  },
  {
    name: contributorsdcI3sfM7uNMeta?.name ?? "publications-id-contributors",
    path: contributorsdcI3sfM7uNMeta?.path ?? ":id()/contributors",
    meta: contributorsdcI3sfM7uNMeta || {},
    alias: contributorsdcI3sfM7uNMeta?.alias || [],
    redirect: contributorsdcI3sfM7uNMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/publications/[id]/contributors.vue").then(m => m.default || m)
  },
  {
    name: formats8n9GQOldXwMeta?.name ?? "publications-id-formats",
    path: formats8n9GQOldXwMeta?.path ?? ":id()/formats",
    meta: formats8n9GQOldXwMeta || {},
    alias: formats8n9GQOldXwMeta?.alias || [],
    redirect: formats8n9GQOldXwMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/publications/[id]/formats.vue").then(m => m.default || m)
  },
  {
    name: indexN7hqAlxdPaMeta?.name ?? "publications-id",
    path: indexN7hqAlxdPaMeta?.path ?? ":id()",
    meta: indexN7hqAlxdPaMeta || {},
    alias: indexN7hqAlxdPaMeta?.alias || [],
    redirect: indexN7hqAlxdPaMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/publications/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: library_45offersqzGRtfuqkSMeta?.name ?? "publications-id-library-offers",
    path: library_45offersqzGRtfuqkSMeta?.path ?? ":id()/library-offers",
    meta: library_45offersqzGRtfuqkSMeta || {},
    alias: library_45offersqzGRtfuqkSMeta?.alias || [],
    redirect: library_45offersqzGRtfuqkSMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/publications/[id]/library-offers.vue").then(m => m.default || m)
  },
  {
    name: mainECCpzpK5WkMeta?.name ?? "publications-id-main",
    path: mainECCpzpK5WkMeta?.path ?? ":id()/main",
    meta: mainECCpzpK5WkMeta || {},
    alias: mainECCpzpK5WkMeta?.alias || [],
    redirect: mainECCpzpK5WkMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/publications/[id]/main.vue").then(m => m.default || m)
  },
  {
    name: pricesDuXLkf6bSDMeta?.name ?? "publications-id-prices",
    path: pricesDuXLkf6bSDMeta?.path ?? ":id()/prices",
    meta: pricesDuXLkf6bSDMeta || {},
    alias: pricesDuXLkf6bSDMeta?.alias || [],
    redirect: pricesDuXLkf6bSDMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/publications/[id]/prices.vue").then(m => m.default || m)
  },
  {
    name: related_45contents3O53Eyz648Meta?.name ?? "publications-id-related-contents",
    path: related_45contents3O53Eyz648Meta?.path ?? ":id()/related-contents",
    meta: related_45contents3O53Eyz648Meta || {},
    alias: related_45contents3O53Eyz648Meta?.alias || [],
    redirect: related_45contents3O53Eyz648Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/publications/[id]/related-contents.vue").then(m => m.default || m)
  },
  {
    name: sales_45rights1EbwwRvFQ0Meta?.name ?? "publications-id-sales-rights",
    path: sales_45rights1EbwwRvFQ0Meta?.path ?? ":id()/sales-rights",
    meta: sales_45rights1EbwwRvFQ0Meta || {},
    alias: sales_45rights1EbwwRvFQ0Meta?.alias || [],
    redirect: sales_45rights1EbwwRvFQ0Meta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/publications/[id]/sales-rights.vue").then(m => m.default || m)
  },
  {
    name: indexSUwaqOBqZdMeta?.name ?? "publications",
    path: indexSUwaqOBqZdMeta?.path ?? "",
    meta: indexSUwaqOBqZdMeta || {},
    alias: indexSUwaqOBqZdMeta?.alias || [],
    redirect: indexSUwaqOBqZdMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/publications/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: securityuuQCcpTopPMeta?.name ?? "user-security",
    path: securityuuQCcpTopPMeta?.path ?? "/user/security",
    meta: securityuuQCcpTopPMeta || {},
    alias: securityuuQCcpTopPMeta?.alias || [],
    redirect: securityuuQCcpTopPMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/user/security.vue").then(m => m.default || m)
  },
  {
    name: settings58UOXn7mWiMeta?.name ?? "user-settings",
    path: settings58UOXn7mWiMeta?.path ?? "/user/settings",
    meta: settings58UOXn7mWiMeta || {},
    alias: settings58UOXn7mWiMeta?.alias || [],
    redirect: settings58UOXn7mWiMeta?.redirect,
    component: () => import("/opt/buildhome/repo/pages/user/settings.vue").then(m => m.default || m)
  }
]