import type { CookieRef } from 'nuxt/dist/app/composables/cookie';
import type { User, KeyCookieCurrentUser, KeyCookie, CookieValue } from '../server/cookie';

export type CookieData = { [key in KeyCookie]: CookieValue };

/**
* Get cookie
* @returns cookie
*/
// export const useHCookie = (): CookieRef<KeyCookie> => useCookie('dash', { path: '/' });
export const useHCookie = (): CookieRef<{ [key in KeyCookie]: string }> => useCookie('dash', { path: '/' });

// export interface CookieData {
//   [key in KeyCookie]: string | null
// }

/**
 * Get value from the cookie key passed
 * @param {string} key
 * @returns {object|string|null} value
 */
/* eslint-disable  @typescript-eslint/no-explicit-any */
export function getCookieData(key: KeyCookie): CookieValue {
  const cookieData = useHCookie().value as CookieData;
  return (cookieData) ? cookieData[key] : undefined;
}

/**
 * Update the current user stored into the cookie or create it if it doesn't exist
 * @param user
 * @returns
 */
export const updateCookieUser = (user: Partial<User>): void => {
  const cookie = useHCookie();
  const cookieData = cookie.value;
  if (!cookieData) return;

  const instanceName = cookieData.instance_name;
  if (instanceName) {
    const key = `current_user_${instanceName}` as KeyCookieCurrentUser;
    const currentUser = cookieData[key] as User;
    cookieData[key] = { ...currentUser, ...user } as string;
  }
  cookie.value = cookieData;
};
