/**
 * Provide a graphql client based on the instance stored in the cookie
 */
import urql, { createClient, fetchExchange } from '@urql/vue';
import { authExchange } from '@urql/exchange-auth';
import type { Operation, AnyVariables } from '@urql/core';
import type { AuthConfig } from '@urql/exchange-auth';
import type { keyAccessToken } from '~~/server/cookie';
import type { CookieData } from '~~/composables/cookie';
import type { CookieRef } from 'nuxt/dist/app/composables/cookie';
import type { NuxtRuntimeConfig } from '@nuxt/types/config/runtime';

export type GraphqlError = {
  code: string;
  message: string;
  field: string;
};

export default defineNuxtPlugin((nuxt) => {
  const { vueApp } = nuxt;

  const router = useRouter();

  const runtimeConfig = useRuntimeConfig() as NuxtRuntimeConfig;

  const instance = runtimeConfig.public.instances[getInstanceName()];

  const cookie: CookieRef<CookieData> = useHCookie();

  const cookieData: CookieData = cookie.value;
  /**
   * Get API instance name from the cookie
   * @returns {string} The 4 letters instance name
   */
  if (!instanceName()) return;

  const key: keyAccessToken = `access_token_${instanceName()}`;
  /**
   * Get the access token from the cookie
   * @returns {string|null} The access token
   */
  const accessToken = cookieData[key];

  /**
   * Get host url from the config for the right instance
   * @param {string} key
   * @returns {string} value
   */
  const url = `${instance.api['host']}graphql`;

  /**
   * Instanciate a graphQL API client
   * @returns {object} graphQL API client
   */
  const apiClient = createClient({
    url: url,
    exchanges: [
      authExchange(async (utils): Promise<AuthConfig> => {
        const token = accessToken;
        return {
          /* eslint-disable  @typescript-eslint/no-explicit-any */
          addAuthToOperation: (operation): Operation<any, AnyVariables> => {
            if (!token) return operation;
            return utils.appendHeaders(operation, {
              Authorization: `Bearer ${token}`,
            });
          },
          didAuthError: (error): boolean => {
            // server not responding
            if (error.response === undefined) {
              cookieData[`referrer_${instanceName()}`] = router.currentRoute.value.fullPath;
              cookie.value = cookieData;
              router.push('/500?msg=service_unavailable');
              return false;

              // unauthorized, token expired ?
            } else if (error.response.status == 401) {
              // clean current user and jwt
              delete cookieData[`current_user_${instanceName()}`];
              delete cookieData[`access_token_${instanceName()}`];
              const referrerPath = router.currentRoute.value.fullPath;

              cookieData[`referrer_${instanceName()}`] = referrerPath;
              cookie.value = cookieData;

              if (window) window.location.href = referrerPath;
              return false;
            }

            // check if the error was an auth error (this can be implemented in various ways, e.g. 401 or a special error code)
            return error.graphQLErrors.some(
              (e) => e.extensions?.code === 'FORBIDDEN',
            );
          },
          refreshAuth: async (): Promise<void> => {},
        };
      }),
      fetchExchange,
    ],
  });

  vueApp.use(urql, apiClient);

  return {
    provide: {
      apiClient,
    },
  };
});
