<script setup lang="ts">
const runtimeConfig = useRuntimeConfig();
const config = runtimeConfig.public;

import './assets/tailwind.scss';
import './assets/hub.scss';
import GridOverlay from '~~/components/GridOverlay.vue';
import { useI18n } from 'vue-i18n';
const i18n = useI18n();

const favicon = useFavicon();
const instanceName = getInstanceName();
const bodyClass = `theme-${instanceName}`;
const link: ComputedRef = computed(() => favicon);
useHead({ link });
</script>
<template>
  <Html :lang="i18n.locale.value" />
  <GridOverlay v-if="config.hub_env === 'staging'" />
  <Body :class="bodyClass">
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </Body>
</template>
