<script setup lang="ts">
const columns = [
  'outter',
  'large',
  'medium',
  'small',
  'xsmall',
  'xsmall',
  'xsmall',
  'xsmall',
  'small',
  'medium',
  'large',
  'outter',
];

const keysDown = { u: false, i: false };
const isGridVisible = ref(false);

const addKey = (event: KeyboardEvent): void => {
  if (event.key === 'u') keysDown.u = true;
  if (event.key === 'i') keysDown.i = true;

  if (keysDown.u && keysDown.i) isGridVisible.value = !isGridVisible.value;
};

const removeKey = (event: KeyboardEvent): void => {
  if (event.key === 'u') keysDown.u = false;
  if (event.key === 'i') keysDown.i = false;
};

onMounted(() => {
  document.addEventListener('keydown', addKey);
  document.addEventListener('keyup', removeKey);
});

onBeforeUnmount(() => {
  document.removeEventListener('keydown', addKey);
  document.removeEventListener('keyup', removeKey);
});
</script>
<template>
  <div
    v-if="isGridVisible"
    class="grid-overlay"
  >
    <div class="grid-overlay__wrapper">
      <div
        v-for="column in columns"
        :key="column"
        :class="[`grid-overlay-${column} bg-red`]"
      />
    </div>
  </div>
</template>
<style scoped>
.grid-overlay {
  @apply fixed top-0 left-1/2 min-w-[1024px] max-w-[1600px] w-full h-full z-error-msg pr-xxl translate-x-[-50%];
}

.grid-overlay__wrapper {
  @apply w-full opacity-50 h-full mx-auto;
  @apply grid grid-cols-12 gap-xxl;
}
</style>
