import posthog from 'posthog-js';
import { nextTick } from 'vue';

export default defineNuxtPlugin((nuxt) => {
  const runtimeConfig = useRuntimeConfig();
  const config = runtimeConfig.public;

  if (config.env !== 'development') {
    const { vueApp } = nuxt;
    const router = useRouter();
    const instName = getInstanceName();

    posthog.init(config.posthog_key, {
      api_host: config.posthog_host,
      capture_pageview: false,
      loaded: () => {
        const user = currentUser();
        if (user.exists) {
          posthog.identify(encodeURIComponent(`${instName}-${user.id}`));
          posthog.people.set({
            email: user.email,
            instance: instName,
            organization: user?.organization?.name,
          });
        }
      },
    });

    // Inject PostHog into the application and make it available via this.$posthog (or app.$posthog)
    vueApp.provide('posthog', posthog);

    // Make sure that pageviews are captured with each route change
    router.afterEach((to) => {
      nextTick().then(() => {
        /* Note: this might also be a good place to call posthog.register(...) in order to update your properties
        on each page view
        */
        posthog.capture('$pageview', {
          $current_url: to.fullPath,
        });
      });
    });
  }
});
