import { defineStore } from 'pinia';
import type { Organization } from './organization';
export interface User {
  id?: string;
  email: string;
  firstName: string;
  lastName: string;
  fullName: string;
  username: string;
  isRoot: boolean;
  language: string;
  organization: Partial<Organization> | null;

  /* methods */
  exists?: boolean;
}

export const useUser = defineStore('user', {
  state: (): User => {
    return {
      id: undefined,
      email: '',
      firstName: '',
      lastName: '',
      fullName: '',
      username: '',
      language: 'fr-FR',
      isRoot: false,
      organization: null,
    };
  },

  getters: {
    exists(): boolean {
      return !!this.id;
    },
  },

  actions: {
    update(data: Partial<User>) {
      this.id = data?.id || this.id;
      this.email = data?.email || this.email;
      this.firstName = data?.firstName || this.firstName;
      this.lastName = data?.lastName || this.lastName;
      this.fullName = data?.fullName || this.fullName;
      this.isRoot = data?.isRoot || this.isRoot;
      this.language = data?.language || this.language;
      this.username = data?.username || this.username;
      if (data?.organization || this.organization) {
        const org = data?.organization || this.organization;
        this.organization = (org) ? {
          id: org.id,
          broadcasterId: org.broadcasterId,
          name: org.name,
          identifier: org.identifier,
          nature: org.nature,
          currency: org.currency,
          sellForLoans: org.sellForLoans,
          countryReferencePrice: org.countryReferencePrice,
        } : null;
      }

      return this;
    },
  },
});
